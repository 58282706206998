import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  labourData: string[] = ['Contract','Wages',];
  titleData: string[] = ['Mr','Mrs','Ms'];
  salaryTypeData:string[]=['Weekly','Monthly','yearly'];
  workTypeData:string[]=['Permanent','Temp','Vegas'];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  genderData:string[]=['Female','Male']
  keyword1="projectName";
  siteNoData:any[]=[];
  siteData:any[]=[];
  getSite:any[]=[];
  projectName:any[]=[];
  employeeForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  salaryConfig:boolean = false;
  designationData:any[]=[];
  Designation:any[]=[];
  keyword4='designationName'
  designationName:any[]=[];
  photo:any;
  id:any;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }


    this.employeeForm= this.formBuilder.group({
      employeeId: ['', Validators.required],
      title: ['',Validators.required],
      firstName: ['',Validators.required],
      lastName:  ['',Validators.required],
      designationName: ['', Validators.required],
      workType: ['',Validators.required],
      salaryType: ['', Validators.required],
      salary:['',Validators.required],
      gender:['',Validators.required],
      mobileNo:['',Validators.required],
      emailId:['',Validators.required],
      panNo:['',Validators.required],
      aadharNo:['',Validators.required],
      address:['',Validators.required],
      photo:['']

     
      
    });
   
 
  }

  goBack() {
    this.router.navigate(['/dashboard/employee']);
  }
 
  get f() {
    return this.employeeForm.controls;
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.photo = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.employeeForm.patchValue({
      employeeId: data.employeeId,
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      designationName: data.designationName,
      workType: data.workType,
      salaryType: data.salaryType,
      salary:data.salary,
      gender:data.gender,
      mobileNo:data.mobileNo,
      emailId:data.emailId,
      panNo:data.panNo,
      aadharNo:data.aadharNo,
      address:data.address,
      photo:data.photo
    })
  }

  onSubmit(data: any) {
    if (this.employeeForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format()
    //data.isActive = true;
    data.photo = this.photo

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/employee'])
    })
  }
  onCancel(){
    this.employeeForm.reset()
  }
  selectEvent4(item:any) {
    // do something with selected item
    console.log(item)
   
    this.employeeForm.patchValue({
      designationName:item.designationName,
    })
  
  }
 
  onChangeSearch4(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getDesignation().subscribe((val:any)=>{  
      
        this.designationName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
        console.log(this.designationData)
        return this.designationName.filter(val => val.toLowerCase().includes(search))
      
    })
  }

  onFocused4(e:any) {
    console.log(e)
    // do something
  }
}
