
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Employee</h2>
    <form [formGroup]="employeeForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="employeeId">Employee Id<span class="requried-field">*</span></label>
          <input
          type="text"
          id="employeeId"
          placeholder="Enter employeeId"
          class="form-control"
          formControlName="employeeId"
          [ngClass]="{ 'is-invalid': submitted && f['employeeId'].errors }"
        />
          
          <div *ngIf="submitted && f['employeeId'].errors" class="invalid-feedback">
            <div *ngIf="f['employeeId'].errors['required']">Employee Id is required</div>
          </div>
        </div>
      
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="title">Title<span class="requried-field">*</span></label>
            <select
            id="title"
            name="title"
            class="form-control"
            formControlName="title"
            [ngClass]="{ 'is-invalid': submitted && f['title'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of titleData"
              [value]="data"
              >{{ data}}
            </option>
          </select>
            <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
              <div *ngIf="f['title'].errors['required']">Title is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="firstName">First Name<span class="requried-field">*</span></label>
            <input
              type="text"
              id="firstName"
              placeholder="Enter firstName"
              class="form-control"
              formControlName="firstName"
              [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }"
            />
            <div *ngIf="submitted && f['firstName'].errors" class="invalid-feedback">
              <div *ngIf="f['firstName'].errors['required']">firstName is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="lastName">Last Name<span class="requried-field">*</span></label>
            <input
              type="text"
              id="lastName"
              placeholder="Enter lastName"
              class="form-control"
              formControlName="lastName"
              [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }"
            />
            <div *ngIf="submitted && f['lastName'].errors" class="invalid-feedback">
              <div *ngIf="f['lastName'].errors['required']">lastName is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="designationName">Designation<span class="requried-field">*</span></label>
            <input
            type="text"
            id="designationName"
            placeholder="Enter designationName"
            class="form-control"
            formControlName="designationName"
            [ngClass]="{ 'is-invalid': submitted && f['designationName'].errors }"
          />
            <div *ngIf="submitted && f['designationName'].errors" class="invalid-feedback">
              <div *ngIf="f['designationName'].errors['required']">Designation is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="workType">workType<span class="requried-field">*</span></label>
            <select
            id="workType"
            name="worktype"
            class="form-control"
            formControlName="workType"
            [ngClass]="{ 'is-invalid': submitted && f['workType'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of workTypeData"
              [value]="data"
              >{{ data}}
            </option>
          </select>
            <div *ngIf="submitted && f['workType'].errors" class="invalid-feedback">
              <div *ngIf="f['workType'].errors['required']">workType is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
          <label for="salaryType">salary Type<span class="requried-field">*</span></label>
          <select
          id="salaryType"
          name="salaryType"
          class="form-control"
          formControlName="salaryType"
          [ngClass]="{ 'is-invalid': submitted && f['salaryType'].errors }"
        >
        <option value="" selected disabled>--Please select--</option>
          <option
            *ngFor="let data of salaryTypeData"
            [value]="data"
            >{{ data}}
          </option>
        </select>
          <div *ngIf="submitted && f['salaryType'].errors" class="invalid-feedback">
            <div *ngIf="f['salaryType'].errors['required']">Salary is required</div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="salary">Salary<span class="requried-field">*</span></label>
            <input
              type="text"
              id="salary"
              placeholder="Enter salary"
              class="form-control"
              formControlName="salary"
              [ngClass]="{ 'is-invalid': submitted && f['salary'].errors }"
            />
            <div *ngIf="submitted && f['salary'].errors" class="invalid-feedback">
              <div *ngIf="f['salary'].errors['required']">Salary is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="mobileNo">Mobile No<span class="requried-field">*</span></label>
            <input
              type="text"
              id="mobileNo"
              placeholder="Enter mobileNo"
              class="form-control"
              formControlName="mobileNo"
              [ngClass]="{ 'is-invalid': submitted && f['mobileNo'].errors }"
            />
            <div *ngIf="submitted && f['mobileNo'].errors" class="invalid-feedback">
              <div *ngIf="f['mobileNo'].errors['required']">mobileNo is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="panNo">Pan No<span class="requried-field">*</span></label>
            <input
              type="text"
              id="panNo"
              placeholder="Enter panNo"
              class="form-control"
              formControlName="panNo"
              [ngClass]="{ 'is-invalid': submitted && f['panNo'].errors }"
            />
            <div *ngIf="submitted && f['panNo'].errors" class="invalid-feedback">
              <div *ngIf="f['panNo'].errors['required']">Pan No is required</div>
            </div>
          </div>
     
      <div class="form-group col-sm-12 col-md-12 col-lg-6">
        <label for="emailId">Email Id<span class="requried-field">*</span></label>
        <input
          type="text"
          id="emailId"
          placeholder="Enter emailId"
          class="form-control"
          formControlName="emailId"
          [ngClass]="{ 'is-invalid': submitted && f['emailId'].errors }"
        />
        <div *ngIf="submitted && f['emailId'].errors" class="invalid-feedback">
          <div *ngIf="f['emailId'].errors['required']">Email is required</div>
        </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="gender">Gender<span class="requried-field">*</span></label>
            <select
            id="gender"
            name="gender"
            class="form-control"
            formControlName="gender"
            [ngClass]="{ 'is-invalid': submitted && f['gender'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of genderData"
              [value]="data"
              >{{ data}}
            </option>
          </select>
            <div *ngIf="submitted && f['gender'].errors" class="invalid-feedback">
              <div *ngIf="f['gender'].errors['required']">gender is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="aadharNo">Adhar No<span class="requried-field">*</span></label>
            <input
              type="text"
              id="aadharNo"
              placeholder="Enter aadharNo"
              class="form-control"
              formControlName="aadharNo"
              [ngClass]="{ 'is-invalid': submitted && f['aadharNo'].errors }"
            />
            <div *ngIf="submitted && f['aadharNo'].errors" class="invalid-feedback">
              <div *ngIf="f['aadharNo'].errors['required']">Adhar is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="address">Address<span class="requried-field">*</span></label>
            <input
              type="text"
              id="address"
              placeholder="Enter address"
              class="form-control"
              formControlName="address"
              [ngClass]="{ 'is-invalid': submitted && f['address'].errors }"
            />
            <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
              <div *ngIf="f['address'].errors['required']">Address is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="photo">Photo<span class="requried-field">*</span></label>
            <input type="file" id="photo" class="form-control" formControlName="photo" (change)="onFileSelected($event)" />
            <div *ngIf="submitted && f['photo'].errors" class="invalid-feedback">
              <div *ngIf="f['photo'].errors['required']">photo is required</div>
            </div>
          </div>
         
      </div>
    <!-- </div> -->

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(employeeForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  